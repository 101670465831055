<template>
    <MeasurementItem
        :link-to="
            !isShared
                ? {
                      name: 'tachograph',
                      params: { id: tracker.id },
                  }
                : null
        "
    >
        <template #content>
            <div class="no-padding">
                <h3 v-if="driverName">{{ driverName.value }}</h3>

                <div v-if="vrn">
                    {{ $t('vrn') }}:
                    <b>{{ vrn.value }}</b>
                </div>

                <div>
                    {{ $t('vehicle') }}:
                    <b>{{ tracker.asset_details.name }}</b>
                </div>

                <div v-if="workingState" class="l-inline l-center-v l-gap-0">
                    <FilledCircleIcon
                        width="12"
                        height="12"
                        :class="
                            workingStateClassNames[workingState.value] ||
                                'orange'
                        "
                    />

                    <h3>
                        {{
                            $root.$te(
                                `shared.tachograph.working_state.${workingState.value}`
                            )
                                ? $t(
                                      `shared.tachograph.working_state.${workingState.value}`
                                  )
                                : workingState.value
                        }}
                    </h3>
                </div>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'TruckInfoMeasurementFragment',
    components: {
        FilledCircleIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            workingStateClassNames: {
                0: 'green',
                2: 'blue',
                3: 'red',
            },
        }
    },
    computed: {
        driverName() {
            return this.getMeasurementItem('driver_name')
        },
        vrn() {
            return this.getMeasurementItem('vrn')
        },
        workingState() {
            return this.getMeasurementItem('selected_activity')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "vehicle": "Vehicle",
        "vrn": "License plate"
    },
    "de": {
        "vehicle": "Fahrzeug",
        "vrn": "Kontrollschild"
    },
    "fr": {
        "vehicle": "Véhicule",
        "vrn": "Plaque d'immatriculation"
    },
    "it": {
        "vehicle": "Veicolo",
        "vrn": "Targa"
    }
}
</i18n>

<style lang="scss" scoped>
.blue {
    color: $color-blue-light;
}

.green {
    color: $color-green;
}

.orange {
    color: $color-orange;
}

.red {
    color: $color-red;
}
</style>
